.w-checkbox {
  margin-bottom: 5px;
  padding-left: 20px;
  display: block;
}

.w-checkbox:before {
  content: " ";
  -ms-grid-column-span: 1;
  -ms-grid-column: 1;
  -ms-grid-row-span: 1;
  -ms-grid-row: 1;
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox:after {
  content: " ";
  -ms-grid-column-span: 1;
  -ms-grid-column: 1;
  -ms-grid-row-span: 1;
  -ms-grid-row: 1;
  clear: both;
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox-input {
  float: left;
  margin: 4px 0 0 -20px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  background-image: url("https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: #3898ec;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

body {
  color: #333;
  font-family: Rubik, sans-serif;
  font-size: 14px;
  line-height: 1.2;
}

h2 {
  text-transform: capitalize;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
}

p {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 1.6;
}

a {
  text-decoration: underline;
}

strong {
  font-weight: 600;
}

.section {
  background-color: #f3f3f3;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
}

.section.section--grey {
  color: #fff;
  background-color: #333;
  position: relative;
}

.section.section--dummy {
  height: 1000px;
}

.container {
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}

.container.container--justify-center {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.container.container--narrow {
  padding-left: 20%;
  padding-right: 20%;
}

.navbar {
  z-index: 99;
  color: #fff;
  background-color: #333;
  position: sticky;
  top: 0;
}

.nav-link {
  height: 100%;
  color: #b3b3b3;
  align-items: center;
  display: flex;
}

.nav-link:hover {
  color: #fff;
}

.nav-link.is--about {
  color: #ddd;
  background-color: #ffffff1a;
}

.nav-link.is--about:hover {
  background-color: #fff3;
}

.nav-link.is--about:active {
  color: #fff;
  background-color: #ffffff59;
}

.navbar__inner {
  height: 60px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.nav-menu {
  height: 100%;
  align-items: center;
  display: flex;
}

.layout-grid {
  display: -ms-grid;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.layout-grid.layout-grid--3-4-1-4 {
  -ms-grid-columns: 1fr minmax(0px, -webkit-max-content);
  -ms-grid-columns: 1fr minmax(0px, max-content);
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-template-columns: 1fr minmax(0px, -webkit-max-content);
  grid-template-columns: 1fr minmax(0, max-content);
  align-content: start;
  align-items: start;
  position: relative;
}

.layout-grid.layout-grid--vis {
  min-height: 100%;
  -ms-grid-columns: 25% 1fr;
  grid-template-columns: 25% 1fr;
  position: relative;
}

.filter-panel {
  z-index: 1;
  width: 300px;
  background-color: #fff;
  padding: 16px;
  display: block;
  position: sticky;
  top: 82px;
  box-shadow: 0 10px 20px #0000000d;
}

.vis-block {
  grid-auto-columns: 1fr;
  grid-auto-rows: -webkit-max-content;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr;
  -ms-grid-rows: -webkit-max-content;
  -ms-grid-rows: max-content;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  grid-template-rows: -webkit-max-content;
  grid-template-rows: max-content;
  padding-top: 16px;
}

.vis-block.basic-grid {
  z-index: 2;
  position: relative;
}

.heading {
  margin-bottom: 0;
}

.heading.heading--underline {
  border-bottom: 1px solid #0000001a;
  margin-top: 0;
  padding-bottom: 8px;
}

.heading.heading--mb {
  margin-bottom: 12px;
}

.heading.heading--clear {
  margin-top: 0;
}

.expand-block {
  background-color: #fff;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  margin-bottom: -1px;
}

.expand-block.expand-block--first {
  border-top-color: #0000;
}

.expand-block__trigger {
  min-height: 44px;
  color: #333;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  padding-right: 8px;
  font-weight: 600;
  text-decoration: none;
  transition: all .1s;
  display: flex;
}

.expand-block__trigger:hover {
  background-color: #00000005;
}

.expand-block__content, .expand-block__content.expand-block__content--open {
  overflow: hidden;
}

.expand-block__trigger_icon {
  width: 24px;
  height: 24px;
  overflow: hidden;
}

.expand-block__content_inner {
  padding-top: 8px;
  padding-bottom: 16px;
  display: flex;
}

.checkbox {
  width: 20px;
  height: 20px;
  -ms-grid-row-align: center;
  cursor: pointer;
  background-color: #0000001a;
  border-style: none;
  border-radius: 0;
  flex: none;
  align-self: center;
  margin-top: 0;
  margin-left: 0;
  margin-right: 10px;
}

.checkbox.w--redirected-checked {
  background-color: #333;
  background-size: 16px 16px;
}

.checkbox-label {
  align-items: center;
  margin-bottom: 0;
  display: flex;
}

.filter-checkbox {
  width: 100%;
  height: 36px;
  background-color: #00000005;
  align-items: stretch;
  margin-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
}

.filter-checkbox:hover {
  background-color: #0000000d;
}

.filter-checkbox.filter-checkbox--dropdown {
  padding-left: 8px;
  padding-right: 8px;
}

.checkbox-list {
  margin-bottom: 0;
}

.content__scroll {
  max-height: 240px;
  flex: 1;
  padding-right: 6px;
  overflow: auto;
}

.filter-dropdown {
  flex-direction: column;
  flex: 1;
  display: flex;
  position: relative;
}

.dropdown-toggle {
  z-index: 2;
  width: 100%;
  height: 44px;
  background-color: #00000005;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  display: flex;
  position: relative;
}

.dropdown-toggle:hover {
  background-color: #0000000d;
}

.dropdown-list {
  z-index: 2;
  max-height: 400px;
  background-color: #fff;
  flex: 1;
  margin-top: 44px;
  display: none;
  position: absolute;
  inset: 0% 0% auto;
  box-shadow: 0 4px 4px #0003;
}

.dropdown-list.w--open {
  background-color: #fff;
  border: 1px solid #0000001a;
  border-top-style: none;
  box-shadow: 0 2px 10px -6px #000;
}

.dropdown__icon_expand {
  margin-left: auto;
}

.dropdown__icon_list {
  opacity: .3;
  margin-right: 8px;
}

.filter-header {
  min-height: 44px;
  color: #333;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  padding-right: 8px;
  font-weight: 600;
  text-decoration: none;
  transition: all .1s;
  display: flex;
}

.filter-section {
  padding-bottom: 16px;
}

.search__wrapper {
  height: 44px;
  flex: 1;
  align-items: stretch;
  margin-bottom: 0;
  display: flex;
  position: relative;
}

.search-field {
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0%;
}

.search-input {
  width: 100%;
  height: 44px;
  background-color: #f3f3f3;
  border: 1px #000;
  margin-bottom: 0;
  padding-left: 44px;
  padding-right: 40px;
  transition: border-color .4s cubic-bezier(.23, 1, .32, 1), box-shadow .4s cubic-bezier(.23, 1, .32, 1);
}

.search-input:hover, .search-input:focus {
  border-color: #408cff;
}

.search__icon {
  width: 44px;
  color: #408cff;
  justify-content: center;
  align-items: center;
  padding-left: 4px;
  display: flex;
  position: absolute;
  inset: 0% auto 0% 0%;
}

.search-icon {
  opacity: .3;
}

.dropdown-list__items {
  max-height: 340px;
  margin-top: 8px;
  margin-bottom: 0;
  margin-right: 4px;
  padding-left: 6px;
}

.loading-gif {
  width: 44px;
  height: 44px;
  max-width: none;
  flex: none;
  display: block;
}

.loading-gif.is--small {
  width: 22px;
  height: 22px;
}

.button {
  height: 40px;
  color: #333;
  background-color: #0000000d;
  justify-content: center;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  text-decoration: none;
  display: flex;
}

.button:hover {
  color: #000;
  background-color: #0000001a;
}

.button:active {
  background-color: #0003;
}

.button.clear-filters {
  margin-top: 24px;
}

.button.download-selected {
  margin-left: auto;
}

.button.button--margin {
  margin-bottom: 8px;
  margin-right: 8px;
}

.button.useful-link {
  flex: none;
  margin-bottom: 8px;
  margin-right: 8px;
}

.button.is--green {
  background-color: #019a61;
}

.button.is--report-year {
  min-width: 5.8em;
  margin-bottom: 8px;
  margin-right: 8px;
}

.current-info__selected {
  grid-auto-flow: column;
  grid-auto-columns: -webkit-max-content;
  -ms-grid-columns: -webkit-max-content;
  -ms-grid-columns: max-content;
  grid-auto-columns: max-content;
  grid-template-columns: -webkit-max-content;
  -ms-grid-rows: auto;
  flex-wrap: wrap;
  grid-template-rows: auto;
  grid-template-columns: max-content;
  margin-bottom: -8px;
  padding-top: 0;
  display: flex;
}

.block {
  background-color: #0000000d;
  padding: 12px;
  display: flex;
}

.block.block--margin {
  margin-bottom: 8px;
  margin-right: 8px;
}

.button-icon {
  margin-right: 6px;
}

.current-info__wrapper {
  justify-content: space-between;
  display: flex;
}

.current-info__options {
  flex: none;
}

.data-vis {
  z-index: 1;
  display: none;
  position: relative;
}

.data-vis.is--active {
  display: block;
}

.legend__wrapper {
  flex-wrap: wrap;
  margin-bottom: -8px;
  margin-right: -8px;
  display: flex;
}

.legend__wrapper.is--hidden {
  display: none;
}

.legend-swatch {
  width: 16px;
  height: 16px;
  background-color: #b3b3b3;
  margin-right: 8px;
}

.legend-swatch.legend-swatch--colour-1 {
  background-color: #dd3954;
}

.legend-swatch.legend-swatch--colour-2 {
  background-color: #0394c9;
}

.legend-swatch.legend-swatch--colour-3 {
  background-color: #019a61;
}

.legend-swatch.legend-swatch--colour-4 {
  background-color: #f5d62e;
}

.legend-swatch.legend-swatch--colour-5 {
  background-color: #19191a;
}

.modal__wrapper {
  z-index: 100;
  display: none;
  position: fixed;
  inset: 0%;
}

.modal__wrapper.webflow {
  display: flex;
}

.beneficiary-info {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  background-color: #fff;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  padding: 24px;
  box-shadow: 0 10px 18px #0000001a;
}

.beneficiary-detail__wrapper {
  z-index: 2;
  display: flex;
  position: absolute;
  inset: auto 0% 60px;
}

.beneficiary-detail__wrapper.webflow {
  display: flex;
}

.header__close {
  width: 44px;
  height: 44px;
  cursor: pointer;
  background-color: #0000000d;
  justify-content: center;
  align-items: center;
  display: flex;
}

.header__close:hover {
  background-color: #0000001a;
}

.header.header--with-close {
  display: -ms-grid;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr -webkit-max-content;
  -ms-grid-columns: 1fr max-content;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr -webkit-max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-template-columns: 1fr max-content;
  align-items: center;
  display: grid;
}

.basic-grid {
  display: -ms-grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: -webkit-max-content;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr;
  -ms-grid-rows: -webkit-max-content;
  -ms-grid-rows: max-content;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  grid-template-rows: -webkit-max-content;
  grid-template-rows: max-content;
  display: grid;
}

.beneficiary-data {
  max-height: 300px;
  margin-top: 10px;
  position: relative;
  overflow: auto;
}

.beneficiary-info__wrapper {
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: -8px;
  margin-right: -8px;
  display: flex;
}

.beneficiary-info__total {
  grid-auto-flow: column;
  grid-auto-columns: -webkit-max-content;
  -ms-grid-columns: -webkit-max-content;
  -ms-grid-columns: max-content;
  grid-auto-columns: max-content;
  grid-template-columns: -webkit-max-content;
  -ms-grid-rows: auto;
  flex-wrap: wrap;
  grid-template-rows: auto;
  grid-template-columns: max-content;
  display: flex;
}

.beneficiary-info__options {
  flex-wrap: wrap;
  flex: none;
  margin-bottom: -8px;
  margin-right: -8px;
  display: none;
}

.cipc-listing-link {
  font-size: 15px;
  font-weight: 400;
}

.nav-logo {
  height: 100%;
  color: #fff;
  align-items: center;
  font-size: 24px;
  text-decoration: none;
  display: flex;
}

.basic-rich-text h2 {
  text-transform: none;
  margin-top: 0;
  font-size: 22px;
  font-weight: 500;
}

.basic-rich-text p {
  opacity: .63;
}

.col.col--sticky {
  position: sticky;
  top: 60px;
}

.col.col--grid {
  display: -ms-grid;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  align-content: start;
  display: grid;
}

.col.col--grid.col--sticky {
  top: 60px;
}

.expand-block__rich-text {
  padding-left: 12px;
  padding-right: 12px;
}

.expand-block__rich-text h3 {
  margin-top: 0;
  font-size: 14px;
}

.hidden {
  display: none;
}

.mobile-toggle-open {
  height: 44px;
  color: #fff;
  cursor: pointer;
  background-color: #333;
  justify-content: center;
  align-items: center;
  display: none;
  position: absolute;
  inset: auto 100% 70px -96px;
  box-shadow: 10px 0 10px -8px #0000000d;
}

.mobile-toggle-open:active {
  background-color: #19191a;
}

.filter-icon {
  margin-right: 8px;
}

.div-block {
  flex: none;
  display: flex;
}

.mobile-toggle-close {
  height: 44px;
  color: #fff;
  cursor: pointer;
  background-color: #333;
  justify-content: center;
  align-items: center;
  display: none;
  position: absolute;
  inset: 180px 100% auto -44px;
  box-shadow: 10px 0 10px -8px #0000000d;
}

.mobile-toggle-close:active {
  background-color: #19191a;
}

.filters-panel-close {
  z-index: 1000;
  background-color: #0000001a;
  display: none;
  position: fixed;
  inset: 0%;
}

.filter-loading {
  width: 100%;
  height: 36px;
  background-color: #00000005;
  align-items: stretch;
  margin-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
}

.filter-loading:hover {
  background-color: #0000000d;
}

.filter-loading.filter-checkbox--dropdown {
  padding-left: 8px;
  padding-right: 8px;
}

.filter-loading__icon {
  width: 20px;
  height: 20px;
  -ms-grid-row-align: center;
  flex: none;
  align-self: center;
  margin-right: 10px;
}

.filter-loading__label {
  align-items: center;
  display: flex;
}

.filter-current-list {
  flex-wrap: wrap;
  margin-top: 1em;
  display: flex;
}

.filter-current-list.hidden {
  display: none;
}

.filter-current {
  height: 36px;
  color: #fff;
  background-color: #333;
  align-items: stretch;
  margin-bottom: 8px;
  margin-right: 8px;
  padding-left: 12px;
  font-size: 13px;
  display: flex;
  overflow: hidden;
}

.filter-current:hover {
  background-color: #19191a;
}

.filter-current.filter-checkbox--dropdown {
  padding-left: 8px;
  padding-right: 8px;
}

.filter-current__label {
  -ms-grid-row-align: center;
  align-self: center;
}

.filter-current__remove {
  width: 36px;
  cursor: pointer;
  padding: 8px;
}

.current-info__showing, .current-info__total-value, .beneficiary-info__grants-awarded, .beneficiary-info__total-value {
  font-weight: 600;
}

.beneficiary-data__row {
  display: -ms-grid;
  grid-column-gap: 12px;
  -ms-grid-columns: 150px 150px 150px 150px;
  -ms-grid-rows: auto;
  background-color: #00000005;
  border-bottom: 1px solid #0000001a;
  grid-template-rows: auto;
  grid-template-columns: 150px 150px 150px 150px;
  grid-auto-columns: 1fr;
  align-items: center;
  padding: 10px;
  display: grid;
}

.beneficiary-data__row.beneficiary-data__row--header {
  z-index: 2;
  background-color: #fff;
  font-weight: 600;
  position: sticky;
  top: 0;
}

.beneficiary-data__row-number {
  width: 4ch;
  border-right: 1px solid #0000001a;
  justify-content: center;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
}

.beneficiary-data__row-code {
  border-right: 1px solid #0000001a;
  padding-top: 4px;
  padding-bottom: 4px;
}

.beneficiary-data__row-allocation {
  border-right: 1px solid #0000001a;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 8px;
}

.vis-loading {
  z-index: 9999;
  height: 100%;
  background-color: #00000005;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  position: relative;
}

.beneficiary-data__row-date {
  border-right: 1px solid #0000001a;
  padding-top: 4px;
  padding-bottom: 4px;
}

.legend-block {
  margin-bottom: 8px;
  margin-right: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  font-size: 13px;
  display: flex;
}

.legend-block.block--margin {
  margin-bottom: 8px;
  margin-right: 8px;
}

.beneficiary-detail__close {
  z-index: 1;
  background-color: #0000001a;
  position: absolute;
  inset: 0%;
}

.useful-links___icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.useful-links {
  z-index: 3;
  grid-auto-flow: column;
  grid-auto-columns: -webkit-max-content;
  -ms-grid-columns: -webkit-max-content;
  -ms-grid-columns: max-content;
  grid-auto-columns: max-content;
  grid-template-columns: -webkit-max-content;
  -ms-grid-rows: auto;
  flex-wrap: wrap;
  grid-template-rows: auto;
  grid-template-columns: max-content;
  margin-bottom: 16px;
  display: none;
  position: relative;
}

.beneficiary-data__row-province {
  border-right: 1px solid #0000001a;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 8px;
}

.text-block {
  width: 100%;
  margin-bottom: 8px;
}

.filter-panel__close-button {
  cursor: pointer;
  display: none;
}

.icon {
  margin-right: 8px;
}

.dropdown-close {
  z-index: 1;
  display: none;
  position: fixed;
  inset: 0%;
}

.about__wrapper {
  z-index: 99999;
  background-color: #0000008c;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  inset: 0%;
}

.about-modal {
  z-index: 1;
  width: 100%;
  max-width: 960px;
  background-color: #fff;
  margin-left: 24px;
  margin-right: 24px;
  padding: 32px;
  display: block;
  position: relative;
  box-shadow: 0 10px 20px #0000000d;
}

.modal__close {
  z-index: 1;
  width: 44px;
  height: 44px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 16px 16px auto auto;
}

.modal__inner {
  width: 100%;
}

.modal-content {
  margin-top: 16px;
  margin-bottom: 0;
  overflow: auto;
}

.about-logos {
  border-top: 1px solid #0000001a;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2em;
  padding-top: 2em;
  display: flex;
}

.about-logo {
  width: 200px;
  padding-left: 2em;
  padding-right: 2em;
}

.feedback__wrapper {
  z-index: 1000;
  margin-right: -21px;
  display: none;
  position: fixed;
  inset: auto 0% 5em auto;
  transform: rotate(-90deg);
}

.feedback {
  color: #fff;
  background-color: #019a61;
  justify-content: center;
  align-items: center;
  padding: .8em;
  line-height: 1;
  text-decoration: none;
  transition: padding-right .2s;
  display: flex;
  transform: rotate(0deg);
}

.feedback:hover {
  background-image: linear-gradient(#0000000d, #0000000d);
}

.paragraph {
  margin-bottom: 0;
}

.search-loading {
  z-index: 1;
  width: 2em;
  height: 2em;
  background-color: #f3f3f3;
  justify-content: center;
  align-items: center;
  display: none;
  position: absolute;
  inset: auto auto auto 8px;
}

.beneficiary-data__row-allocation-date {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 8px;
}

.useful-link__modal-wrapper {
  justify-content: center;
  display: flex;
  position: fixed;
  inset: 0%;
}

.link-modal__wrapper {
  z-index: 100000;
  background-color: #0000008c;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  inset: 0%;
}

.link-modal__wrapper.webflow {
  display: flex;
}

.useful-modal {
  z-index: 1;
  width: 100%;
  max-width: 960px;
  background-color: #fff;
  margin-left: 24px;
  margin-right: 24px;
  padding: 32px;
  display: flex;
  position: relative;
  box-shadow: 0 10px 20px #0000000d;
}

.useful-modal.is--nlc {
  display: flex;
}

.useful-modal.is--b2b, .useful-modal.is--cipc, .useful-modal.is--npo {
  display: none;
}

.modal-buttons {
  margin-top: 1em;
  display: flex;
}

.button-text {
  color: #fff;
  margin-bottom: 0;
}

.button-text.is--dark {
  color: #333;
}

.useful-modal__close {
  z-index: 1;
  width: 44px;
  height: 44px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 16px 16px auto auto;
}

.data-list {
  display: none;
}

.data-list.is--active {
  display: block;
}

.grant-data {
  margin-top: 10px;
  position: relative;
}

.grant-data__row {
  display: -ms-grid;
  -ms-grid-columns: 40% 20% 20% 20%;
  -ms-grid-rows: auto;
  color: #333;
  background-color: #00000005;
  border-bottom: 1px solid #0000001a;
  grid-template-rows: auto;
  grid-template-columns: 40% 20% 20% 20%;
  grid-auto-columns: 1fr;
  align-items: center;
  padding: .8em .4em;
  text-decoration: none;
  display: grid;
}

.grant-data__row:hover {
  background-color: #0000000d;
}

.grant-data__row:active {
  background-color: #0000001a;
}

.grant-data__row.beneficiary-data__row--header {
  z-index: 2;
  background-color: #fff;
  font-weight: 600;
  position: sticky;
  top: 0;
}

.grant-data__row.grant-data__row--header {
  z-index: 2;
  background-color: #fff;
  border-bottom-style: none;
  padding: .4em;
  font-weight: 600;
  position: sticky;
  top: 82px;
  box-shadow: 0 3px 10px -6px #0000001a;
}

.grant-data__row-beneficiary {
  border-right: 1px solid #0000000d;
  flex: 1;
  padding: .2em .8em;
}

.grant-data__row-beneficiary.is--header {
  border-right-style: none;
  padding-left: 0;
}

.grant-data__row-amount {
  border-right: 1px solid #0000001a;
  flex: 1;
  padding: .2em .8em;
}

.grant-data__row-amount.is--header {
  border-right-style: none;
  padding-left: 0;
}

.grant-data__row-year {
  border-right: 1px solid #0000001a;
  flex: 1;
  padding: .2em .8em;
}

.grant-data__row-year.is--header {
  border-right-style: none;
  padding-left: 0;
}

.grant-data__row-category {
  flex: 1;
  padding: .2em .8em;
}

.grant-data__row-category.is--header {
  padding-left: 0;
}

.grant-data__row_inner {
  color: #333;
  cursor: pointer;
  border-right: 1px solid #0000001a;
  align-items: center;
  padding: .8em;
  text-decoration: none;
  display: flex;
  position: relative;
}

.grant-data__row_inner:hover {
  background-color: #00000005;
}

.grant-data__row_inner:active {
  background-color: #0000000d;
}

.grant-data__row_inner.is--fourth {
  border-right-style: none;
}

.sort-arrow {
  width: 2em;
  height: 16px;
  opacity: .1;
}

.sort-arrow.is--selected {
  opacity: 1;
}

.data-list__loading {
  background-color: #0000000d;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  padding: .8em;
  display: flex;
}

.data-list__loading-text {
  margin-left: 1em;
}

.data-view__wrapper {
  margin-top: 1em;
}

.data-view__buttons {
  border-bottom: 1px solid #0000001a;
  margin-bottom: 1em;
  display: flex;
}

.select-button--list {
  color: #333;
  background-color: #0000000d;
  justify-content: center;
  align-items: center;
  margin-right: .4em;
  padding: .8em 1.4em;
  font-weight: 500;
  text-decoration: none;
  transition: all .2s;
  display: flex;
  position: relative;
  top: 0;
}

.select-button--list:hover {
  background-color: #0000001a;
}

.select-button--list:active {
  background-color: #0003;
}

.select-button--list.is--active {
  color: #fff;
  background-color: #333;
  font-weight: 500;
}

.select-button__text {
  margin-bottom: 0;
}

.select-button__icon {
  width: 1em;
  height: 1em;
  margin-right: .8em;
}

.select-button--vis {
  color: #333;
  background-color: #0000000d;
  justify-content: center;
  align-items: center;
  margin-right: .4em;
  padding: .8em 1.4em;
  font-weight: 500;
  text-decoration: none;
  transition: all .2s;
  display: flex;
  position: relative;
  top: 0;
}

.select-button--vis:hover {
  background-color: #0000001a;
}

.select-button--vis:active {
  background-color: #0003;
}

.select-button--vis.is--active {
  color: #fff;
  background-color: #333;
  font-weight: 500;
}

.report-years__wrapper {
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 1.5em;
  display: flex;
}

@media screen and (max-width: 991px) {
  h3 {
    font-size: 16px;
    line-height: 1.2;
  }

  .section {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .container {
    padding-left: 16px;
    padding-right: 16px;
  }

  .container.container--mobile-fullwidth {
    padding-left: 0;
    padding-right: 0;
  }

  .layout-grid, .layout-grid.layout-grid--3-4-1-4, .layout-grid.layout-grid--vis {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .filter-panel {
    z-index: 1001;
    width: 300px;
    max-width: 90vw;
    padding: 16px 12px 12px 16px;
    font-size: 12.5px;
    position: fixed;
    inset: 60px -300px 0 auto;
  }

  .filter-panel.webflow {
    right: 0;
  }

  .vis-block.basic-grid {
    padding-top: 16px;
  }

  .expand-block__content_inner {
    padding-bottom: 6px;
  }

  .content__scroll {
    max-height: none;
  }

  .dropdown-list__items.content__scroll {
    max-height: 340px;
  }

  .beneficiary-info {
    padding: 16px;
  }

  .beneficiary-detail__wrapper {
    bottom: 0;
  }

  .basic-grid {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }

  .mobile-toggle-open {
    display: flex;
  }

  .mobile-toggle-close {
    display: none;
  }

  .filter-panel__inner {
    height: 100%;
    overflow: scroll;
  }

  .filter-panel__close-button {
    width: 44px;
    height: 44px;
    background-color: #333;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: auto auto 70px -44px;
  }

  .icon {
    margin-right: 0;
  }

  .about-modal {
    z-index: 1001;
    font-size: 12.5px;
  }

  .about-modal.webflow {
    right: 0;
  }

  .modal__close {
    width: 44px;
    height: 44px;
    justify-content: center;
    align-items: center;
  }

  .modal__inner {
    height: 100%;
  }

  .modal-close__icon {
    margin-right: 0;
  }

  .feedback__wrapper {
    top: 110px;
    bottom: auto;
    right: 0%;
  }

  .useful-modal {
    z-index: 1001;
    font-size: 12.5px;
  }

  .useful-modal.webflow {
    right: 0;
  }

  .useful-modal__close {
    width: 44px;
    height: 44px;
    justify-content: center;
    align-items: center;
  }

  .grant-data__row-beneficiary {
    padding-left: 0;
  }
}

@media screen and (max-width: 767px) {
  body {
    font-size: 12.5px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 14px;
  }

  .filter-panel {
    width: 300px;
    right: -300px;
  }

  .button.download-selected {
    display: none;
  }

  .button.is--report-year {
    width: 100%;
    margin-right: 0;
  }

  .current-info__selected {
    flex: 1;
  }

  .block {
    padding: 8px;
  }

  .current-info__wrapper {
    flex-wrap: wrap;
  }

  .current-info__options {
    width: 100%;
  }

  .beneficiary-info.basic-grid {
    padding-left: 10px;
    padding-right: 10px;
  }

  .beneficiary-info__inner {
    height: 100%;
  }

  .beneficiary-data__row {
    grid-column-gap: 6px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .legend-block {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
  }

  .about-modal {
    padding: 24px 16px 16px;
  }

  .modal__close {
    top: 8px;
    right: 8px;
  }

  .modal-content {
    max-height: 70vh;
  }

  .useful-modal {
    padding: 24px 16px 16px;
  }

  .useful-modal__close {
    top: 8px;
    right: 8px;
  }

  .grant-data__row {
    -ms-grid-columns: 50% 30% 20%;
    grid-template-columns: 50% 30% 20%;
    padding-left: .4em;
    padding-right: .4em;
  }

  .grant-data__row.grant-data__row--header {
    grid-column-gap: 0px;
  }

  .grant-data__row-beneficiary {
    padding-left: .4em;
    padding-right: .4em;
  }

  .grant-data__row-year {
    border-right-style: none;
  }

  .grant-data__row-category {
    padding-left: .4em;
    padding-right: .4em;
    display: none;
  }

  .grant-data__row_inner.is--fourth {
    display: none;
  }

  .grant-data__row_inner.is--third {
    border-right-style: none;
  }
}

@media screen and (max-width: 479px) {
  .container {
    padding-left: 8px;
    padding-right: 8px;
  }

  .nav-link.is--about {
    flex: none;
  }

  .button.is--report-year {
    width: 100%;
    min-width: auto;
    margin-right: 0;
  }

  .current-info__wrapper {
    flex-wrap: wrap;
  }

  .sanle-logo {
    width: 190px;
  }

  .about-logos {
    width: 100%;
    flex-flow: wrap;
    align-items: center;
  }

  .about-logo {
    width: 50%;
    min-width: 170px;
    padding-bottom: 2em;
  }

  .grant-data__row {
    -ms-grid-columns: 35% 40% 25%;
    grid-template-columns: 35% 40% 25%;
  }

  .grant-data__row-amount.is--header {
    padding-right: 0;
  }

  .grant-data__row_inner.is--first {
    padding-left: .4em;
  }

  .sort-arrow {
    width: 1em;
  }
}

@media screen and (max-width: 991px) {
  #w-node-_37ecb32d-cd92-b46c-71a4-480ef6d64e6f-7b6952ca {
    -ms-grid-column: 1;
    grid-column-start: 1;
  }
}

/*# sourceMappingURL=index.a45e6329.css.map */
